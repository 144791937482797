import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'

const Repertoire = ({ data }) => (
    <Layout>
    <div className="fixed-top bg-light py-lg-3 text-center">
    <h1 className="d-none d-lg-inline-block mb-0 h2 lishan-xue">Lishan Xue</h1><br />
    <p className="d-none d-lg-inline-block fst-italic title">Pianist</p>
        <nav className="navbar navbar-expand-lg navbar-purple bg-light mx-3 mx-lg-none">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <h1 className="d-lg-none pl-2 h2 fw-bold mobile-title">Lishan Xue<br /><span className="fst-italic h6 fw-normal">Pianist</span></h1>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/">Home | <span className="text-muted">主页</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/bio">About | <span className="text-muted">个人</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/news">News | <span className="text-muted">新闻</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/concerts">Concerts | <span className="text-muted">演出</span></a>
                    </li>
                    <li className="nav-item dropdown" id="media">
                        <a className="nav-link dropdown-toggle" href="/media" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Media | <span className="text-muted">媒体</span>
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/media/#videos">Videos</a>
                        <a className="dropdown-item" href="/media/#photos">Photos</a>
                        </div>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="/repertoire">Repertoire | <span className="text-muted">常备曲目</span> <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/teaching">Teaching | <span className="text-muted">教学</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact | <span className="text-muted">联系方式</span></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <div className="container pt-3 pb-5">
    <p className="text-end"><sup>⭑</sup> <span className="fst-italic">indicates that it has been performed in public</span></p>
        <div className="row">
            <div className="col-12 col-lg">
            {/* Solo  */}
            <h2 className="h5 mb-3"><span className="gradient">Solo</span></h2>
            {data.soloone.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-2">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-3" />
                </div>
            ))}
            <div className="spacing"></div>
            {data.solotwo.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-2">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-3" />
                </div>
            ))}
            <h3 className="h6 mt-5 mb-3 mt-2"><span className="gradient">Works by Chinese Composers</span></h3>
            {data.solothree.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-2">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-3" />
                </div>
            ))}
            {/* Concerto */}
            <h2 className="h5 mt-5 mb-3"><span className="gradient">Concerto</span></h2>
            {data.concerto.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            {/* Chamber */}
            <h2 className="h5 mt-5 mb-3"><span className="gradient">Chamber Music and Ensemble</span></h2>
            {data.chamberone.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            <div className="spacing"></div>
            {data.chambertwo.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            </div>
            {/* Collaborative */}
            <div className="col-12 col-lg">
            <h2 className="h5 mt-5 mb-3 mt-lg-0"><span className="gradient">Collaborative Music</span></h2>
            {/* String */}
            <h3 className="h6 mb-3"><span className="gradient">String</span></h3>
            {data.stringone.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            <div className="spacing"></div>
            {data.stringtwo.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            <div className="spacing"></div>
            {data.stringthree.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            {/* Winds */}
            <h3 className="h6 mt-5 mb-3"><span className="gradient">Wood Winds</span></h3>
            {data.windsone.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            <div className="spacing"></div>
            {data.windstwo.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            <div className="spacing"></div>
            {data.windsthree.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            {/* Brass */}
            <h3 className="h6 mt-5 mb-3"><span className="gradient">Brass</span></h3>
            {data.brassone.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            <div className="spacing"></div>
            {data.brasstwo.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            {/* Voice */}
            <h3 className="h6 mt-5 mb-3"><span className="gradient">Voice</span></h3>
            {data.voiceone.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            <div className="spacing"></div>
            {data.voicetwo.nodes.map(node => (
                <div key={node.recordId}>
                <p className="bio-text fw-bold mb-0">{node.data.composer}</p>
                <div dangerouslySetInnerHTML={{
                __html: node.data.titles.childMarkdownRemark.html,
                }} className="bio-text mb-2" />
                </div>
            ))}
            </div>
        </div>
    </div>
    </Layout>
)
export default Repertoire;

export const query = graphql`
{
    soloone: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Solo"}, part: {eq: "one"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    solotwo: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Solo"}, part: {eq: "two"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    solothree: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Solo"}, part: {eq: "three"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    concerto: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Concerto"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    chamberone: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Chamber"}, part: {eq: "one"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    chambertwo: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Chamber"}, part: {eq: "two"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    stringone: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-String"}, part: {eq: "one"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    stringtwo: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-String"}, part: {eq: "two"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    stringthree: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-String"}, part: {eq: "three"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    windsone: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-Winds"}, part: {eq: "one"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    windstwo: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-Winds"}, part: {eq: "two"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    windsthree: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-Winds"}, part: {eq: "three"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    brassone: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-Brass"}, part: {eq: "one"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    brasstwo: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-Brass"}, part: {eq: "two"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    voiceone: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-Voice"}, part: {eq: "one"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }

    voicetwo: allAirtable(filter: {table: {eq: "Repertoire"}, data: {section: {eq: "Collaborative-Voice"}, part: {eq: "two"}}}, sort: {fields: data___year, order: ASC}) {
        nodes {
            data {
                composer
                titles {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            recordId
        }
    }
}  
`;